<template>
  <div>
      <HistoryComplaintList />
  </div>
</template>

<script>
import HistoryComplaintList from '@/components/Dashboard/HistoryComplaint/HistoryComplaintList'
export default {
  components: {
    HistoryComplaintList
  }
}
</script>