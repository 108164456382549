<template>
  <div v-if="complaint" class="w-full flex flex-row">
    <div v-if="loading" class="spinner">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>

    <div
      v-if="loading === false"
      class="
        w-full
        md:w-10/12
        max-w-screen-xl
        flex flex-col
        lg:flex-col lg:items-start
      "
    >
      <div
        class="
          w-full
          md:w-10/12
          mb-4
          bg-white
          rounded-xl
          shadow-lg
          text-gray-600
        "
        :v-if="complaint"
      >
        <div
          class="
            px-6
            py-2
            rounded-lg
            flex
            justify-between
            items-center
            cursor-pointer
          "
          @click="toggleAccordion"
          style="border-bottom: gray solid 1px"
        >
          <p class="text-dark text-left"><b>Información de la queja</b></p>
          <span
            class="h-5 w-5 m-2 flex justify-center items-center transition"
            :class="{ 'transform rotate-180': accordionOpen }"
          >
            <img src="@/assets/icons/arrow-down.svg" alt="" />
          </span>
        </div>
        <div v-if="accordionOpen" class="p-3">
          <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
              <BaseInput
                type="text"
                label="Nombre consumidor financiero"
                v-model="complaint.consumidor_financiero"
                placeholder=""
                :disabled="true"
              />
              <BaseInput
                type="text"
                label="Id. de radicado"
                v-model="complaint.codigo_queja"
                placeholder=""
                :disabled="true"
              />
            </div>
          </div>
          <div class="flex flex-row gap-2">
            <div class="w-11/12 grid grid-cols-2 gap-2">
              <BaseInput
                type="text"
                label="Entidad"
                v-model="complaint.entidad"
                placeholder=""
                :disabled="true"
              />
              <div
                class="
                  w-full
                  mt-5
                  text-left
                  flex flex-col
                  border-gray-300
                  placeholder-gray-400
                "
              >
                <label class="text-xs md:text-xs text-gray-600 text-opacity-80"
                  ><b>Estado</b>
                </label>
                <div
                  class="
                    w-full
                    h-full
                    mt-2
                    border border-gray-50
                    rounded-lg
                    text-xs
                    md:text-sm
                    flex flex-col
                    items-center
                  "
                >
                  <ComplaintStatusLabel
                    v-if="complaint.estado_queja"
                    :status="complaint.estado_queja"
                    danger="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Segmentos de las fechas -->
      <div
        class="
          w-full
          md:w-10/12
          mb-4
          bg-white
          rounded-xl
          shadow-lg
          text-gray-600
        "
        :v-if="complaint"
      >
        <div
          class="
            px-6
            py-2
            rounded-lg
            flex
            justify-between
            items-center
            cursor-pointer
          "
          @click="toggleAccordion2"
          style="border-bottom: gray solid 1px"
        >
          <p class="text-dark text-left"><b>Histórico de la queja</b></p>
          <span
            class="h-5 w-5 m-2 flex justify-center items-center transition"
            :class="{ 'transform rotate-180': accordionOpen2 }"
          >
            <img src="@/assets/icons/arrow-down.svg" alt="" />
          </span>
        </div>
        <div v-if="accordionOpen2" class="p-3">
          <div class="flex flex-col gap-2 mb-4">
            <div
              v-for="option in complaint.actualizaciones"
              :key="option.fecha_actualizada"
              class="
                w-full
                mt-2
                border border-gray-50
                rounded-lg
                text-xs
                md:text-sm
                flex flex-col
                items-center
                border-gray-300
                placeholder-gray-400
              "
            >
              <div class="w-11/12 grid grid-cols-2 gap-2 mb-4">
                <BaseInput
                  type="text"
                  label="Actualización queja"
                  v-model="option.fecha"
                  placeholder=""
                  :disabled="true"
                />
                <div class="mt-2 text-left flex flex-row gap-2 items-center">
                  <button
                    class="
                      mt-3
                      w-48
                      rounded-md
                      h-10
                      w-10
                      mx-auto
                      mt-auto
                      hover:bg-blue-500
                      general-color-button
                      flex flex-row
                      justify-center
                      items-center
                    "
                    @click="onDetail(option.fecha_actualizada)"
                  >
                    <p class="text-xs text-white">Ver detalle</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import ComplaintStatusLabel from "../Complaints/ComplaintStatusLabel";
export default {
  components: {
    ComplaintStatusLabel,
  },
  async created() {
    const { complaint_code } = this.$route.params;
    this.loading = true;
    let { error, data } = await this.$api.getListBigquery(
      `?codigo_queja=${complaint_code}`
    );
    this.loading = false;

    if (error)
      this.showToast("error", "Error consultando históricos de la queja.");

    if (data) {
      const complaint = data;
      this.complaint = {
        ...complaint,
        actualizaciones: complaint.actualizaciones.map((item) => {
          return {
            ...item,
            fecha: format(
              new Date(item.fecha_actualizada),
              "dd/MM/yyyy hh:mm a"
            ),
          };
        }),
      };
    }
  },
  data() {
    return {
      customer: {},
      complaint: {},
      criterion: null,
      accordionOpen: false,
      accordionOpen2: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    onDetail(date) {
      this.$router.push(
        `/dashboard/complaints/historical/${this.complaint.codigo_queja}/${date}`
      );
    },
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen;
    },
    toggleAccordion2() {
      this.accordionOpen2 = !this.accordionOpen2;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>

<style>
.spinner {
  width: 100vw;
  z-index: 9000;
  margin-top: 10%;
  position: relative;
}
</style>